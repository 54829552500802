import React from "react";
import { Container } from "@mui/material";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import style from "./style.module.css";
import { useNavigate } from "react-router-dom";
import { getCookie } from '../../../../../Helper/Cokkies'
import { NavLink } from "reactstrap";
import ApkButton from "../../../../../components/Apkbutton/ApkButton";

export default function FooterLinks() {
  const navigate = useNavigate()
  const token = getCookie("token")
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="bg-main">
      <Container className="py-4">
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <List>
              <ul className={style.footerLinkList}>
                <li>
                  <h5>Other</h5>
                </li>
                <li className={style.footerLink}>
                  <NavLink
                    onClick={(e) => {
                      goToTop();
                      navigate("/")
                    }}
                    href="#"
                  >
                    Browse Supplier
                  </NavLink>
                </li>
                <li className={style.socialLinks}>
                  <a aria-label={"Facebook"} href="https://www.facebook.com/people/World-Web-Trade/61553264824773" className={style.socialBtn} style={{ background: "#4867AA" }} target="_">
                    <i className="fab fa-facebook-square"></i>
                  </a>
                  <a aria-label={"Twitter"} href="https://twitter.com/worldwebtrade" className={style.socialBtn} style={{ background: "#1DA1F2" }} target="_">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a aria-label={"Linkedin"} href="https://www.linkedin.com/company/worldwebtrade" className={style.socialBtn} style={{ background: "#007BB6" }} target="_">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                  <a aria-label={"Pinterest"} href="https://in.pinterest.com/worldwebtradepromotion" className={style.socialBtn} style={{ background: "#BD081B" }} target="_">
                    <i className="fab fa-pinterest-p"></i>
                  </a>
                  <a aria-label={"Tumblr"} href="https://www.tumblr.com/blog/view/worldwebtrade" className={style.socialBtn} style={{ background: "#36465D" }} target="_">
                    <i className="fab fa-tumblr-square"></i>
                  </a>
                  <a aria-label={"Instagram"} href="https://www.instagram.com/worldwebtrade" className={style.socialBtn} style={{ background: "#B23A94" }} target="_">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
                <ApkButton />
              </ul>
            </List>
          </Grid>
          <Grid item xs={6} md={3}>
            <List>
              <ul className={style.footerLinkList}>
                <li>
                  <h5>General Links</h5>
                </li>
                <li className={style.footerLink}>
                  <NavLink
                    onClick={(e) => {
                      goToTop();
                      navigate("/about-us")
                    }}
                    href="#"
                  >
                    About us
                  </NavLink>
                </li>
                <li className={style.footerLink}>
                  {" "}
                  <NavLink
                    onClick={(e) => {
                      goToTop();
                      navigate("/contact-us")
                    }}
                    href="#"
                  >
                    Contact us
                  </NavLink>
                </li>
                <li className={style.footerLink}>
                  {" "}
                  <NavLink
                    onClick={(e) => {
                      goToTop();
                      navigate("/term-conditions")
                    }}
                    href="#"
                  >
                    Term & Conditions
                  </NavLink>{" "}
                </li>
                <li className={style.footerLink}>
                  {" "}
                  <NavLink
                    onClick={(e) => {
                      goToTop();
                      navigate("/privacy-policy")
                    }}
                    href="#"
                  >
                    Privacy Policy
                  </NavLink>{" "}
                </li>
              </ul>
            </List>
          </Grid>
          <Grid item xs={6} md={3}>
            <List>
              <ul className={style.footerLinkList}>
                <li>
                  <h5>Our Services</h5>
                </li>
                <li className={style.footerLink}>
                  <NavLink
                    onClick={(e) => {
                      goToTop();
                      navigate("/membership-plans")
                    }}
                    href="#"
                  >
                    Advertise with us
                  </NavLink>
                </li>
                {/* <li className={style.footerLink}>
                  {" "}
                  <NavLink
                    onClick={(e) => {
                      goToTop();
                      navigate("/membership-plans")
                    }}
                    href="#"
                  >
                    Membership plan
                  </NavLink>
                </li> */}
                <li className={style.footerLink}>
                  {" "}
                  <NavLink
                    onClick={(e) => {
                      goToTop();
                      navigate("/")
                    }}
                    href="#"
                  >
                    Banner advertisement
                  </NavLink>{" "}
                </li>
                <li className={style.footerLink}>
                  {" "}
                  <NavLink
                    onClick={(e) => {
                      goToTop();
                      navigate('/job-career')
                    }}
                    href="#"
                  >
                    Jobs & Careers
                  </NavLink>{" "}
                </li>
                <li className={style.footerLink}>
                  {" "}
                  <NavLink
                    onClick={(e) => {
                      goToTop();
                      navigate('/authentication')
                    }}
                    href="#"
                  >
                    Authentication
                  </NavLink>{" "}
                </li>
              </ul>
            </List>
          </Grid>
          <Grid item xs={6} md={3}>
            <List>
              <ul className={style.footerLinkList}>
                <li>
                  <h5>Useful Links</h5>
                </li>
                <li className={style.footerLink}>
                  <NavLink
                    onClick={(e) => {
                      goToTop();
                      navigate('/requirement-form')
                    }}
                    href="#"
                  >
                    Post your requirement
                  </NavLink>
                </li>
                {/* <li className={style.footerLink}>
                  {" "}
                  <NavLink
                    onClick={(e) => {
                      goToTop();
                    }}
                    href="sell-your-product"
                  >
                    Sell your product
                  </NavLink>
                </li> */}
                <li className={style.footerLink}>
                  {" "}
                  <NavLink
                    onClick={(e) => {
                      goToTop();
                      navigate("faq")
                    }}
                    href="#"
                  >
                    FAQ
                  </NavLink>{" "}
                </li>

                {token === undefined ? <li className={style.footerLink}> <NavLink
                  onClick={(e) => {
                    goToTop();
                  }}
                  href="join-form"
                >
                  Join free
                </NavLink></li> : <li className={style.footerLink}><NavLink
                  target={"_blank"}
                  onClick={(e) => {
                    window.open("/admin/add-lead")
                    goToTop();
                  }}
                  href="#"
                >
                  Post buy lead
                </NavLink> </li>}
              </ul>
            </List>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
